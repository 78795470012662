import Parse from 'parse'
import { GoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import fbLogoImg from '../../assets/social/f-logo.png'
import googleLogoImg from '../../assets/social/g-logo-rd.svg'
import { APPID, FB_LOGIN_APP_ID, GOOGLE_CLIENT_ID, SERVER_URL } from '../../constants/ApiEndpoints'
import { facebookLogin, googleLogin } from '../../redux/actions/AuthActions'
import { logEvent } from '../../utils/firebase'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 67px;
  position: relative;

  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 12px;
  border: none;
  cursor: pointer;
`

const Img = styled.img`
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  width: 45px;
  height: 45px;
`

const FBImg = styled(Img)`
  width: 30px;
  height: 30px;
`

const GoogleLoginButton = ({ prefix }) => {
  const dispatch = useDispatch()
  const successResponseGoogle = response => {
    dispatch(googleLogin(response))
  }

  const errorResponseGoogle = response => {
    if (response.error) {
      logEvent('google_login_error_' + response.error)
    }
  }

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={successResponseGoogle}
      onFailure={errorResponseGoogle}
      cookiePolicy={'single_host_origin'}
      render={renderProps => (
        <Button style={{ background: '#131314', color: '#E3E3E3', fontSize: '20px' }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
          <Img src={googleLogoImg} alt="Google" />
          {prefix} with Google
        </Button>
      )}
    />
  )
}

const parseInit = async () => {
  Parse.initialize(APPID)
  Parse.serverURL = SERVER_URL
}

const loadFacebookSDK = async () => {
  if (typeof window === 'undefined') return

  parseInit()

  window.fbAsyncInit = async () => {
    Parse.FacebookUtils.init({
      // this line replaces FB.init({
      appId: FB_LOGIN_APP_ID, //"580230315831350", // Facebook Index ID
      // status: true, // check Facebook Login status
      cookie: true, // enable cookies to allow Parse to access the session
      xfbml: true, // initialize Facebook social plugins on the page
      version: 'v3.2' // point to the latest Facebook Graph API version
    })
    // Run code after the Facebook SDK is loaded.
  }
  ;(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      console.log(d.getElementById(id))
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

parseInit()
loadFacebookSDK()

const SocialLoginCommon = ({ isSignIn, showForm, setShowForm }) => {
  const dispatch = useDispatch()
  const fbLogin = e => {
    e.preventDefault()
    dispatch(facebookLogin)
  }

  //const prefix = isSignIn ? 'Continue' : 'Sign up'
  const prefix = 'Continue'

  return (
    <>
      <Button style={{ background: '#1c77f2', color: 'white', fontSize: '20px' }} onClick={fbLogin}>
        <FBImg src={fbLogoImg} alt="Facebook" />
        {prefix} with Facebook
      </Button>
      <GoogleLoginButton prefix={prefix} />
      {/* {!showForm && (
        <Button
          style={{ background: '#FF5E6D' }}
          onClick={() => setShowForm(true)}
        >
          <Img src={envelopImg} alt="Email" />
          {prefix} with Email
        </Button>
      )} */}
    </>
  )
}

export default SocialLoginCommon
