import dynamic from 'next/dynamic'
import styled from 'styled-components'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { USER_URL } from '../../constants/ApiEndpoints'
import {
  EMAIL_EMPTY,
  PASSWORD_EMPTY,
  SERVER_ERROR
} from '../../constants/FormTranscript'
import { registerParse as registerAction } from '../../redux/actions/AuthActions'
import { awsHeaders } from '../../utils/fetchUtil'
import { logEvent } from '../../utils/firebase'
import { SubmitButton } from '../common/FormControls'
import { Field } from './Field'
import { useRouter } from 'next/router'

const SubmitContainer = styled.div`
  margin-top: 14px;
`

const Formik = dynamic(() =>
  import('formik').then(res => res.Formik, {
    ssr: false
  })
)

const registerSchema = Yup.object().shape({
  email: Yup.string().email().required(EMAIL_EMPTY),
  password: Yup.string().min(6).required(PASSWORD_EMPTY)
})

const checkUser = email => {
  const params = {
    method: 'POST',
    mode: 'cors',
    headers: awsHeaders(),
    body: JSON.stringify({
      username: email
    })
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(USER_URL, params)
      const responseJson = await response.json()

      return resolve(responseJson)
    } catch (error) {
      return reject('server')
    }
  })
}

const SignupForm = props => {
  const { initialValues, setInitialValues } = props
  const router = useRouter()
  const dispatch = useDispatch()

  const [passUserTest, setPassUserTest] = useState(true)

  return (
    <div>
      <Formik
        autoComplete="new-password"
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setFieldError, setFieldValue }) => {
          props.setLoading(true)
          const registerPhase = () => {
            dispatch(registerAction({ ...values }, router)).catch(error => {
              setSubmitting(false)
              if (error.code === 125) {
                if (error.error.indexOf('email') >= 0) {
                  setFieldError('email', error.error)
                }
              } else if (error.code === 202) {
                setFieldError('email', 'Email already exist, try logging in')
              } else {
                setFieldError('email', error.error)
              }

              setFieldValue('password', '', false)
              props.setLoading(false)
            })
          }

          if (passUserTest === false) {
            checkUser(values.email)
              .then(data => {
                if (
                  data.cuser === false &&
                  data.pemail === false &&
                  data.pname === false
                ) {
                  logEvent('signup_submit', {})

                  setPassUserTest(true)
                  registerPhase()
                } else {
                  if (data.cuser === true || data.pemail === true) {
                    setFieldError(
                      'email',
                      'Email already exist. Try logging in.'
                    )
                  }
                  setSubmitting(false)
                  props.setLoading(false)
                }
              })
              .catch(() => {
                setSubmitting(false)
                setFieldError('email', SERVER_ERROR)
                props.setLoading(false)
              })
          } else {
            registerPhase()
          }
        }}
        validationSchema={registerSchema}
      >
        {props => {
          const { isSubmitting, handleSubmit } = props

          return (
            <form
              onSubmit={handleSubmit}
              onChange={({ target }) => {
                setInitialValues({
                  ...initialValues,
                  [target.name]: target.value
                })
              }}
            >
              <Field
                label="Email Address"
                name="email"
                type="email"
                disabled={isSubmitting}
              />
              <Field
                label="Password"
                name="password"
                type="password"
                autoComplete="new-password"
                description="(min. 6 char)"
                maxLength="18"
                showhide="1"
                disabled={isSubmitting}
              />
              <SubmitContainer>
                <SubmitButton
                  type="submit"
                  disabled={isSubmitting}
                  name={'signup'}
                  style={{ fontSize: '20px' }}
                >
                  Free Sign Up
                </SubmitButton>
              </SubmitContainer>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SignupForm
