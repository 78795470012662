import dynamic from 'next/dynamic'
import styled from 'styled-components'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { AUTH_URL } from '../../constants/ApiEndpoints'
import {
  EMAIL_EMPTY,
  PASSWORD_EMPTY,
  SERVER_ERROR
} from '../../constants/FormTranscript'
import { loginParse as loginAction } from '../../redux/actions/AuthActions'
import { awsHeaders } from '../../utils/fetchUtil'
import { SubmitButton } from '../common/FormControls'
import { Field } from './Field'

const SubmitContainer = styled.div`
  margin-top: 14px;
`

const ForgotButton = styled.button`
  font-family: Helvetica Neue;
  font-weight: 500;
  display: inline-block;
  margin-left: auto;
  margin-right: 10px;
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #8b8b8b;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`

const Formik = dynamic(() =>
  import('formik').then(res => res.Formik, {
    ssr: false
  })
)

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(EMAIL_EMPTY),
  password: Yup.string().required(PASSWORD_EMPTY)
})

const checkMigration = (email, password) => {
  const params = {
    method: 'POST',
    mode: 'cors',
    headers: awsHeaders(),
    body: JSON.stringify({
      username: email,
      password: password
    })
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(AUTH_URL, params)
      const responseJson = await response.json()
      if (responseJson.code === 900) {
        return resolve()
      } else {
        return reject(responseJson.message)
      }
    } catch (error) {
      return reject('server')
    }
  })
}

const LoginForm = props => {
  const {
    handleForget,
    handleReturn,
    setLoading,
    initialValues,
    setInitialValues
  } = props
  const [passUserTest, setPassUserTest] = useState(true)
  const dispatch = useDispatch()

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setFieldError, setFieldValue }) => {
          const loginPhase = () => {
            setLoading(true)
            dispatch(loginAction({ ...values }, handleReturn)).catch(error => {
              setSubmitting(false)

              if (error.code === 101) {
                setFieldError('email', error.error)
                setFieldValue('password', '', false)
              } else {
                setFieldError('email', SERVER_ERROR)
              }
              setLoading(false)
            })
          }

          if (passUserTest === false) {
            checkMigration(values.email, values.password)
              .then(() => {
                logEvent('login_submit', {})

                setPassUserTest(true)
                loginPhase()
              })
              .catch(error => {
                setSubmitting(false)

                if (error === 'server') {
                  setFieldError('email', SERVER_ERROR)
                } else {
                  setFieldError('email', error)
                }
              })
          } else {
            loginPhase()
          }
        }}
        validationSchema={loginSchema}
      >
        {props => {
          const { isSubmitting, handleSubmit } = props

          return (
            <form
              onSubmit={handleSubmit}
              onChange={({ target }) => {
                setInitialValues({
                  ...initialValues,
                  [target.name]: target.value
                })
              }}
            >
              <Field
                name="email"
                type="email"
                label="Email Address"
                disabled={isSubmitting}
              />
              <Field
                name="password"
                type="password"
                label="Password"
                disabled={isSubmitting}
                maxLength="18"
                showhide="1"
                description="(min. 6 char)"
                labelButton={
                  <ForgotButton type="button" onClick={handleForget}>
                    Forgot?
                  </ForgotButton>
                }
              />
              <SubmitContainer>
                <SubmitButton
                  type="submit"
                  disabled={isSubmitting}
                  name={'login'}
                  style={{ fontSize: '20px' }}
                >
                  Log In
                </SubmitButton>
              </SubmitContainer>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LoginForm
