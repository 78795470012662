import styled from 'styled-components'
import { useField } from 'formik'

const Root = styled.label`
  display: block;
  margin-bottom: 10px;

  @media only screen and (max-width: 744px) {
    margin-bottom: 9px;
  }
`

export const InputLabel = styled.span`
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  & span {
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: center;

    margin-left: 3px;
    color: #8b8b8b;
  }
`

export const Input = styled.input`
  display: block;
  height: 48px;
  width: 100%;
  background-color: #f5f5f5;
  text-indent: 15px;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  border: 0;
  box-sizing: border-box;
  border-radius: 77px;

  ${({ hasError }) =>
    hasError &&
    `
  border: 4px solid #ffbbc1;
  `}
`

export const Error = styled.span`
  display: block;
  padding: 5px 4px 0 4px;
  color: #ff5e6d;
`

export const Field = ({ label, description, labelButton = null, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <Root>
      <InputLabel>
        {label}
        <span>{description}</span>
        {labelButton}
      </InputLabel>
      <Input {...field} {...props} hasError={meta.touched && meta.error} />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Root>
  )
}
