import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { AUTH_STATE } from '../../constants/AuthActionsConstants'

import appleIcon from '../../assets/apple-icon.svg'
import closeImg from '../../assets/blog/blogClose.svg'
import googlePlayIcon from '../../assets/google-play-icon.svg'
import logo from '../../assets/gradient-logo.svg'
import slider1 from '../../assets/signin-1.png'
import slider2 from '../../assets/signin-2.png'
import slider3 from '../../assets/signin-3.png'
import { LoadingIndicator } from '../Loading/LoadingIndicator'
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import SocialLoginCommon from './SocialLoginCommon'

const SocialLoginSeparator = styled.h2`
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: solid 1px #c4c4c4;
    z-index: -1;
  }

  @media screen and (max-width: 786px) {
    margin: 0;
  }
`

const SocialLoginLine = styled.div`
  margin: 10px;
  padding: 0 20px;
  background: #fff;
  display: inline-block;
  color: #c4c4c4;
  font-size: 12px;
`

export const Logo = styled.img`
  display: block;
  margin: 0 auto 24px;
  height: 40px;
  width: 240px;
  left: 119px;
  object-fit: contain;

  @media only screen and (max-width: 744px) {
    height: 30px;
    width: 168px;
    position: absolute;
    top: 12px;
    left: 20px;
  }
`

const Title = styled.h1`
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin: 0 0 14px;

  @media only screen and (max-width: 744px) {
    font-size: 20px;
    line-height: 20px;
  }
`

const Description = styled.p`
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 0 auto 24px;
  max-width: 428px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);

  transition: visibility 0.2s, opacity 0.2s linear;

  justify-content: space-evenly;
  align-items: center;

  display: flex;
`

const Root = styled.div`
  display: flex;
  width: 1160px;
  height: min(780px, 95vh);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000;

  --root-height: min(780px, 95vh);

  @media only screen and (max-width: 1280px) {
    width: 610px;
  }

  @media only screen and (max-width: 744px) {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
`

const Side = styled.div`
  flex: 1;
  height: var(--root-height);
  position: relative;
  background-color: #fff;

  @media only screen and (max-width: 1280px) {
    &:nth-child(1) {
      display: none;
    }
  }
`

const ScrollSide = styled(Side)`
  overflow-y: auto;

  display: flex;
  align-items: center;
  position: relative;
`

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom left;
`

const Content = styled.div`
  width: 428px;
  margin: auto;

  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0 16px;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 744px) {
    width: 100%;
    padding: 100px 20px 16px;
    margin: 10px auto 20px;
    box-sizing: border-box;
  }
`

const Dots = styled(DotGroup)`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);

  & button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.5;
    border: none;
    line-height: 0;
    padding: 0;
    margin: 0 4px;
    cursor: pointer;

    &:disabled {
      opacity: 1;
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 16px;
  left: calc(100% - 40px);
  width: 27px;
  height: 27px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 0;
  align-self: flex-start;
  z-index: 1000;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(1);
    opacity: 50%;
  }

  @media only screen and (max-height: 780px), only screen and (max-width: 744px) {
    top: 16px;
    width: 19px;
    height: 19px;
  }
`

const ChangeForm = styled.div`
  font-family: Helvetica Neue;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 0 18px;

  & button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-family: Helvetica Neue;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-weight: 700;
    color: #ff5e6d;
    text-decoration: underline;
  }
`

export const Terms = styled.div`
  font-family: Helvetica Neue;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #8b8b8b;
  text-align: center;
  white-space: nowrap;

  @media only screen and (max-width: 744px) {
    white-space: normal;
  }

  & a {
    color: #427bf7;
    cursor: pointer;
  }
`

const AppDownloadText = styled.div`
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin: 32px 0 16px;
`

const AppStoreButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-bottom: 24px;
`

export const StoreButton = styled.a`
  background: ${props => (props.white ? '#FFFFFF' : 'linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%)')};
  border-radius: 37px;
  width: 100%;
  gap: 43px;
  padding: ${props => (props.white ? '7px 0' : '9px 0')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${props => (props.white ? '#000000' : '#ffffff')};
  cursor: pointer;
  margin-right: 10px;
  border: ${props => (props.white ? '1px solid black' : 'none')};

  &:hover {
    background: ${props => (props.white ? '#F5F5F5' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%)')};
  }
`

export const EmailSigninEnableButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-family: Helvetica Neue;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
  text-align: center;
  width: 100%;
  color: gray;
  text-decoration: underline;
  margin-top: 36px;
  color: #4a4a4a;
`

export const InvisibleBox = styled.div`
  height: 70px;
  width: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
`

const slides = [slider1, slider2, slider3]

const SignContainer = props => {
  const { handleReturn, handleSignUp, handleLogIn, authState } = props

  const presetEmail = useSelector(state => state.auth.presetEmail)

  const [initialValues, setInitialValues] = useState({
    email: presetEmail || '',
    password: ''
  })

  const isSignIn = authState === AUTH_STATE.LOGIN
  const [showForm, setShowForm] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const toggleLogIn = () => {
    if (isSignIn) {
      handleSignUp()
    } else {
      handleLogIn()
    }
    setShowForm(true)
  }

  return (
    <Overlay>
      <Root>
        <Side>
          <CarouselProvider naturalSlideWidth={1400} naturalSlideHeight={1922} totalSlides={slides.length} interval={3000} isPlaying infinite isIntrinsicHeight classNameAnimation="carousel-animation" dragEnabled={false}>
            <Slider>
              {slides.map((slide, index) => (
                <Slide index={index}>
                  <SlideImage src={slide} />
                </Slide>
              ))}
            </Slider>
            <Dots />
          </CarouselProvider>
        </Side>
        <ScrollSide>
          <Close onClick={handleReturn}>
            <img src={closeImg} />
          </Close>
          <Content>
            <div>
              <Logo alt="DecorMatters" src={logo} />
              <Title>Join our free interior design app</Title>
              <Description>Decorate your real rooms, play design games, earn rewards, & find interior design inspiration as a part of the DecorMatters community.</Description>
              <SocialLoginCommon isSignIn={isSignIn} showForm={showForm} setShowForm={setShowForm} />
              {!showForm && (
                <>
                  {isSignIn && <EmailSigninEnableButton onClick={() => setShowForm(true)}>Previously signed up with email? Login here</EmailSigninEnableButton>}
                  <AppDownloadText>
                    DecorMatters is better on the App!
                    <br />
                    Download now for the full experience.
                  </AppDownloadText>
                  <AppStoreButtons>
                    <StoreButton href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467" target="_blank">
                      <img src={appleIcon} alt="Apple" />
                      Get it on the App Store
                    </StoreButton>
                    <StoreButton href="https://play.google.com/store/apps/details?id=com.decormatters.prod" target="_blank" white>
                      <img src={googlePlayIcon} alt="Google Play" />
                      Get it on Google Play
                    </StoreButton>
                  </AppStoreButtons>
                </>
              )}
              {showForm && (
                <>
                  <SocialLoginSeparator>
                    <SocialLoginLine>OR</SocialLoginLine>
                  </SocialLoginSeparator>
                  {isSignIn ? (
                    <LoginForm {...props} setLoading={setLoading} initialValues={initialValues} setInitialValues={setInitialValues} />
                  ) : (
                    <SignupForm {...props} setLoading={setLoading} initialValues={initialValues} setInitialValues={setInitialValues} />
                  )}
                </>
              )}
            </div>
            <div>
              <Terms>
                By continuing, you agree to DecorMatters'{' '}
                <a target="_blank" href="/terms">
                  Terms of Service,
                </a>
                <a target="_blank" href="/policy">
                  {' '}
                  Privacy Policy
                </a>
              </Terms>
            </div>
          </Content>
          <InvisibleBox
            onClick={() => {
              handleSignUp()
              setShowForm(true)
            }}
          />
        </ScrollSide>
      </Root>
      {isLoading && (
        <Overlay>
          <LoadingIndicator loading={true} />
        </Overlay>
      )}
    </Overlay>
  )
}

export default SignContainer
